import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroBackgroundImage from '../components/hero-background-image';
import HowItWorks from '../components/how-it-works';
// styles
import styles from './practitioners.module.scss';
import { SwiperComponent } from 'vitl-component-library';

const PractitionersPage = () => {
  const staticData = useStaticQuery(graphql`
    query Practitioners {
      directusPractitioner {
        page_title
        page_description
        hero_title
        hero_description
        hero_button_label
        hero_image_desktop {
          filename_disk
        }
        hero_image_tablet {
          filename_disk
        }
        hero_image_mobile {
          filename_disk
        }
        section_1_title
        section_1_background_image {
          filename_disk
        }
        section_1_bullets {
          title
          description
          filename_disk
        }
        what_you_get_title
        what_you_get_items {
          description
          filename_disk
        }
        section_last_title
        section_last_button_label
        section_last_image_desktop {
          filename_disk
        }
        section_last_image_tablet {
          filename_disk
        }
        section_last_image_mobile {
          filename_disk
        }
      }
    }
  `);

  const { directusPractitioner } = staticData;

  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_button_label,
    hero_image_desktop,
    hero_image_tablet,
    hero_image_mobile,
    section_1_title,
    section_1_background_image,
    section_1_bullets,
    what_you_get_title,
    what_you_get_items,
    section_last_title,
    section_last_button_label,
    section_last_image_desktop,
    section_last_image_tablet,
    section_last_image_mobile,
  } = directusPractitioner;

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroBackgroundImage
        title={hero_title}
        description={hero_description}
        buttonLabel={hero_button_label}
        buttonAction={() => navigate('https://vitlhealth.typeform.com/to/IBvcRr')}
        desktopImage={hero_image_desktop}
        tabletImage={hero_image_tablet}
        mobileImage={hero_image_mobile}
      />

      <section className={styles.sectionProducts}>
        <div className={styles.containerDna}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{section_1_title}</h2>

              <div className={styles.swiperContainer}>
                <SwiperComponent paginationType="bullets" spaceBetween={20} slidesPerView={1}>
                  {section_1_bullets.map((item: any, index: number) => (
                    <div key={index} className={styles.item}>
                      <img
                        className={`${styles.icon} lazyload`}
                        data-src={`${process.env.GATSBY_MEDIA_URL}${item.filename_disk}`}
                        alt={item.title}
                      />
                      <h4 className={styles.title}>{item.title}</h4>
                      <hr />
                      <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  ))}
                </SwiperComponent>
              </div>
            </div>

            <div className={styles.desktopProducts}>
              <img src={`${process.env.GATSBY_MEDIA_URL}${section_1_background_image.filename_disk}`} />
              <div className={styles.productPersonalised}>
                <h4>{section_1_bullets[0].title}</h4>
                <hr />
                <p>{section_1_bullets[0].description}</p>
              </div>
              <div className={styles.productBloodTest}>
                <h4>{section_1_bullets[1].title}</h4>
                <hr />
                <p>{section_1_bullets[1].description}</p>
              </div>
              <div className={styles.productDna}>
                <h4>{section_1_bullets[2].title}</h4>
                <hr />
                <p>{section_1_bullets[2].description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks title={what_you_get_title} items={what_you_get_items} />

      <section className={styles.sectionLast}>
        <div className={styles.sectionLastImages}>
          <img
            className={styles.desktopImage}
            src={`${process.env.GATSBY_MEDIA_URL}${section_last_image_desktop.filename_disk}`}
          />
          <img
            className={styles.tabletImage}
            src={`${process.env.GATSBY_MEDIA_URL}${section_last_image_tablet.filename_disk}`}
          />
          <img
            className={styles.mobileImage}
            src={`${process.env.GATSBY_MEDIA_URL}${section_last_image_mobile.filename_disk}`}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.sectionLastContent}>
              <h2>{section_last_title}</h2>
              <a className={styles.buttonGhost} href="https://vitlhealth.typeform.com/to/IBvcRr" target="_blank">
                {section_last_button_label}
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PractitionersPage;
